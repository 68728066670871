import './DodLayoutOptions.scss';
import classnames from 'classnames';
import { ByzzerChangeEventHandler, ByzzerCheckableChangeEvent } from '@byzzer/ui-components';
import { ByzzerCheckbox } from '@byzzer/ui-components';
import {useEventDataWithUserInfo, useTrackEvent} from "@/analytics/AnalyticsContext";
import {DodLayoutConfig} from '@/types/DodRun';
import { useDodWizard } from '@/components/DodConfigEditor/DodRunConfigWizard/DodWizardContext';
import { intersection } from 'lodash';
import { cdiBdiFactIds, weeksSellingFactIds } from '../../DodFactSetBuilder/DodFactValuePickerTable/DodFactValuePickerTable';

const baseClassName = 'dod-layout-options';

export type DodLayoutOptionsProps = {
    className?: string;
    name?: string;
    value: DodLayoutConfig;
    onChange: ByzzerChangeEventHandler<DodLayoutConfig>;
    mode: string | null;
};

export function DodLayoutOptions({ className, name, value, mode,  onChange, ...props }: DodLayoutOptionsProps) {
    const trackEvent = useTrackEvent();
    const getEventData = useEventDataWithUserInfo();
    const isLayoutMode = mode === 'layout';
    const categoryTotalDisabledTip = 'You cannot include Category Totals when you hide Category from your run.';
    const { runConfig } = useDodWizard();

    function handleValueChange(e: ByzzerCheckableChangeEvent<string>) {
        onChange({
            name,
            value: {
                ...value,
                [e.name!]: e.checked
            }
        });

        // layout options change tracking
        trackEvent({
            type: 'click',
            name: 'dod_layout_options_change',
            data: getEventData({ dodWizardStep: 'layout', option: e.name }),
        });
    }

    function isCategoryHidden(): boolean {
        // disable categoryTotals if category is hidden in layout
        return [...value.columns, ...value.rows].some((axisDef) => axisDef.dim === 'categories' && axisDef.hide);
    }

    const isWorkInProgressFactsSelected = () => {
        return Boolean(intersection(
            runConfig.facts.map((fact) => fact.id),
            [...weeksSellingFactIds, ...cdiBdiFactIds]
        ).length)
    }

    const getDisabledTip = () => {
        let tip: string = '';
        if (
            intersection(
                runConfig.facts.map((fact) => fact.id),
                weeksSellingFactIds
            ).length
        ) {
            tip =
                'The Number of Weeks Selling fact is not compatible with subtotals. Remove these facts to include subtotals.';
        }
        if (
            intersection(
                runConfig.facts.map((fact) => fact.id),
                cdiBdiFactIds
            ).length
        ) {
            tip = 'CDI/BDI facts are not compatible with subtotals. Remove these facts to include subtotals.';
        }

        if (
            intersection(
                runConfig.facts.map((fact) => fact.id),
                weeksSellingFactIds
            ).length &&
            intersection(
                runConfig.facts.map((fact) => fact.id),
                cdiBdiFactIds
            ).length
        ) {
            tip =
                'The Number of Weeks & CDI/BDI facts are not compatible with subtotals. Remove these facts to include subtotals.';
        }
        return tip;
    };

    return (
        <div className={classnames(baseClassName, className)} {...props}>
            <span className={`${baseClassName}__title`}>Layout Options:</span>
            <div className={`${baseClassName}__options`}>
                <ByzzerCheckbox
                    name={'includeSubTotals'}
                    onChange={handleValueChange}
                    checked={value.includeSubTotals}
                    label={'Include Subtotals'}
                    disabled={isLayoutMode || isWorkInProgressFactsSelected()}
                    disabledTip={getDisabledTip()}
                />
                <ByzzerCheckbox
                    name={'includeCategoryTotals'}
                    onChange={handleValueChange}
                    checked={value.includeCategoryTotals}
                    label={'Include Category Totals'}
                    disabled={isLayoutMode || isCategoryHidden()}
                    disabledTip={Boolean(isCategoryHidden()) ? categoryTotalDisabledTip : ''}
                />
                <ByzzerCheckbox
                    name={'includeExtractDate'}
                    onChange={handleValueChange}
                    checked={value.includeExtractDate}
                    label={'Extract Date'}
                    disabled={isLayoutMode}
                />
            </div>
        </div>
    );
}

export default DodLayoutOptions;
